import {
  useState,
  useEffect
} from 'react'

const useRefScrollProgress = (
  inputRef: React.MutableRefObject<unknown>,
  offset = 0
) => {
  const ref = inputRef
  const [start, setStart] = useState<number>(0)
  const [end, setEnd] = useState<number>(0)

  useEffect(() => {
    const updateMethod = () => {
      const rect = (ref.current as HTMLElement).getBoundingClientRect()
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      const offsetTop = rect.top + scrollTop
      setStart(offsetTop / document.body.clientHeight)
      setEnd((offsetTop + rect.height + offset) / document.body.clientHeight)
    }

    if (ref.current !== null) {
      updateMethod()
    }

    const resizeObserver = new ResizeObserver(() => {
      if (ref.current !== null) {
        updateMethod()
      }
    })

    resizeObserver.observe(document.body)

    return () => {
      resizeObserver.disconnect()
    }
  }, [
    setStart,
    ref,
    offset
  ])

  return {
    ref,
    start,
    end
  }
}

export default useRefScrollProgress
