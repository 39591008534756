import React, { useRef } from 'react'
import {
  m,
  useViewportScroll,
  useTransform,
  LazyMotion,
  domAnimation
} from 'framer-motion'

// Components
import Grid, { Col } from '@/components/meta/Grid'
import CardCta from '@/components/molecules/CardCta'

// Hooks
import { useRefScrollProgress } from '@/hooks'

// Styling
import styles from './CtaRow.module.scss'

const CtaRow: React.FC<CtaRowProps> = ({
  title,
  color,
  contactTitle,
  contactDescription,
  buttonTitle = 'Start a project',
  buttonLink = '/contact/#fill-in-form',
  className = ''
}) => {
  const bgRef = useRef(null)

  const { scrollYProgress } = useViewportScroll()
  const { start, end } = useRefScrollProgress(bgRef)

  const scale = useTransform(scrollYProgress, [start, end], [1, 1.2])

  // CTA theme colors
  const freshMint = {
    row: 'fresh-mint-150',
    card: 'fresh-mint-400',
    mark: 'fresh-mint-800'
  }

  const chewyCherry = {
    row: 'chewy-cherry-100',
    card: 'chewy-cherry-250',
    mark: 'chewy-cherry-400'

  }
  return (
    <LazyMotion features={domAnimation} strict>
      <Grid
        as="section"
        className={`${className} ${styles.grid}`}
        cols={12}
        style={{
          '--row-color': `var(--${color === 'fresh-mint' ? freshMint.row : chewyCherry.row})`,
          '--card-color': `var(--${color === 'fresh-mint' ? freshMint.card : chewyCherry.card})`,
          '--mark-color': `var(--${color === 'fresh-mint' ? freshMint.mark : chewyCherry.mark})`
        } as React.CSSProperties}
      >
        <Col
          className={`${styles.cta}`}
          span={10}
          start={2}
        >
          <div
            className={`heading-3-xl ${styles.title}`}
            dangerouslySetInnerHTML={{ __html: title ?? '' }}
          />
          <CardCta
            buttonLink={buttonLink}
            buttonTitle={buttonTitle}
            contactDescription={contactDescription}
            contactTitle={contactTitle}
          />
          <m.span
            className={styles.background}
            ref={bgRef}
            style={{ scale }}
          />
        </Col>
      </Grid>
    </LazyMotion>
  )
}

export default CtaRow
