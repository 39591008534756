import React, {
  useState,
  useCallback,
  useRef
} from 'react'
import Head from 'next/head'
import { Image } from 'react-datocms'
import {
  LazyMotion,
  domAnimation,
  m,
  AnimatePresence
} from 'framer-motion'

// Components
import Grid, { Col } from '@/components/meta/Grid'
import HeaderBase from '@/components/molecules/HeaderBase'
import Button from '@/components/atoms/Button'

// Types
import type { ResponsiveImageType } from 'react-datocms'

export enum Sizes {
  Small = 'sm',
  Medium = 'md',
  Large = 'lg'
}

export enum Alignment {
  Left = 'left',
  Center = 'center',
  Right = 'right'
}

// Styling
import styles from './SectionHeader.module.scss'

// Hooks
import { useMediaQuery, useImageSizes } from '@/hooks'
import LazyImage from '@/components/atoms/LazyImage'

const SectionHeader: React.FC<SectionHeaderProps> = ({
  title,
  rotatingTitles,
  description,
  breadcrumb = [],
  thumbnail,
  thumbnailVideo2,
  thumbnailSize = Sizes.Large,
  thumbnailAlignment = 'right',
  buttonTarget,
  buttonText,
  className = '',
  thumbnailDescription,
  thumbnailDescriptionSize = 'm',
  hasSmallDescription,
  video,
  metaData,
  isDark,
  isJobOpeningClosed,
  hasNoBottomRadius,
  hasBreadcrumb = true
}) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [isPaused, setIsPaused] = useState(true)
  const [isVideoPlayerPreloaded, setIsVideoPlayerPreloaded] = useState(false)
  const isReducedMotion = useMediaQuery('(prefers-reduced-motion: reduce)')
  const {
    srcSet,
    src,
    webpSrcSet
  } = useImageSizes(
    thumbnail?.responsiveImage?.src ?? '',
    [
      300,
      400,
      500,
      600,
      700,
      800,
      900,
      1000,
      1200,
      1400,
      1600,
      1800,
      2000,
      2816
    ],
    1400
  )

  const determineGap = useCallback((size: string | null) => {
    switch (size) {
      case 'large':
        return '32px'
      case 'small':
        return '152px'
      case 'medium':
      default:
        return '76px'
    }
  }, [])

  const style = {
    '--spacing': determineGap(thumbnailSize)
  } as React.CSSProperties

  function onPlay() {
    setIsPaused(false)
    videoRef.current?.play()
  }

  return (
    <>
      {thumbnail?.responsiveImage && (
        <Head>
          <link as="image" href={thumbnail.responsiveImage.src} rel="preload"/>
        </Head>
      )}
      <header
        className={`
          ${className}
          ${styles.header}
          ${isDark ? styles['is-dark'] : ''}
          ${breadcrumb.length > 0 ? styles.bread : ''}
        `}
        style={(thumbnail?.responsiveImage && style) ?? undefined}
      >
        <HeaderBase
          breadcrumb={breadcrumb}
          buttonTarget={buttonTarget ?? ''}
          buttonText={buttonText ?? ''}
          description={description ?? ''}
          hasBreadcrumb={hasBreadcrumb}
          hasSmallDescription={hasSmallDescription}
          isJobOpeningClosed={isJobOpeningClosed}
          rotatingTitles={rotatingTitles}
          single={breadcrumb.length === 1}
          title={title ?? ''}
        />

        {metaData && (
          <>
            {metaData}
          </>
        )}

        {thumbnail && !video && (
          <Grid cols={12} removePadding size="l">
            <Col span={12}>
              <div
                className={`
                  ${styles.thumbnail}
                  ${hasNoBottomRadius ? styles['no-bottom-radius'] : ''}
                  ${thumbnailAlignment !== 'right' ? styles[thumbnailAlignment ?? 'right'] : ''}
                `}
              >
                {thumbnail.format === 'mp4' || thumbnail.format === 'webm'
                  ? (
                    <LazyImage
                      className={styles['lazy-video']}
                      data={thumbnail}
                    />
                  )
                  : (
                    <Image
                      data={{
                        ...thumbnail.responsiveImage as ResponsiveImageType,
                        src,
                        srcSet,
                        webpSrcSet
                      }}
                    />
                  )
                }
              </div>
            </Col>
          </Grid>
        )}

        {video && (
          <Grid cols={12} size="l">
            <Col span={12}>
              <div className={styles.wrapper}>
                {video.video?.src && (
                  <div className={`${!isPaused ? styles.playing : ''} ${styles.player}`}>
                    {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                    <video
                      controls
                      poster={video.video.poster}
                      preload={isVideoPlayerPreloaded ? 'auto' : 'none'}
                      ref={videoRef}
                      src={video.video.src}
                    />
                  </div>
                )}
                <LazyMotion features={domAnimation}>
                  <AnimatePresence>
                    {isPaused && (
                      <m.div
                        className={styles.overlay}
                        exit={{
                          opacity: 0
                        }}
                        initial={{
                          opacity: 1
                        }}
                        onHoverStart={() => setIsVideoPlayerPreloaded(true)}
                        transition={{ type: 'easeOut' }}
                      >
                        <Button
                          className={styles.play}
                          icon="play"
                          label="Play video"
                          onClick={onPlay}
                          size="l"
                          variation="primary"
                        >
                        Play video
                        </Button>
                        {thumbnailVideo2?.video
                          ? (
                            <video
                              /*
                               * The isReduceMotion is not working correctly on the yarn dev local environment because of the serverside rendering
                               * and it being false on the initial state, in the live version this is working
                               */
                              autoPlay={!isReducedMotion}
                              loop
                              muted
                              playsInline
                              poster={thumbnail?.responsiveImage ? thumbnail.responsiveImage.src : ''}
                            >
                              <source src={thumbnailVideo2.url} type={thumbnailVideo2.mimeType} />
                            </video>
                          )
                          : (
                            <div
                              className={styles.placeholder}
                              style={{
                                backgroundImage: thumbnail ? `url(${thumbnail.responsiveImage?.src})` : 'none'
                              } as React.CSSProperties}
                            />
                          )}
                      </m.div>
                    )}
                  </AnimatePresence>
                </LazyMotion>
              </div>
            </Col>
          </Grid>
        )}

        {thumbnailDescription && !isDark && (
          <Grid cols={12} size="l">
            <Col span={7} span1Xl={6} start={2}>
              <div
                className={`
                ${styles['thumbnail-description']}
                ${thumbnailDescriptionSize === 'm' ? 'body-l' : 'body-1-xl'}
              `}
                dangerouslySetInnerHTML={{ __html: thumbnailDescription }}
              />
            </Col>
          </Grid>
        )}
      </header>
    </>
  )
}

export default SectionHeader
