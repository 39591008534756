import React from 'react'

// Components
import ButtonLink from '@/components/atoms/ButtonLink'

// Styling
import styles from './CardCta.module.scss'

// Types
export interface CardCtaProps {
  className?: string
  contactTitle: string | null
  contactDescription?: string | null
  buttonTitle?: string | null
  buttonLink?: string | null
}

const CardCta: React.FC<CardCtaProps> = ({
  className = '',
  contactTitle,
  contactDescription,
  buttonTitle,
  buttonLink
}) => (
  <div
    className={`
      ${styles.card}
      ${className}
    `}
  >
    <h2 className="heading-4-xl">{contactTitle}</h2>
    <p className="body-l">{contactDescription}</p>
    <ButtonLink
      className={styles.button}
      hasNegativeHover
      href={buttonLink?.includes('mailto:') ? `${buttonLink}` : (`${buttonLink}`.startsWith('/') ? `${buttonLink}` : `/${buttonLink}`)}
      size="m"
      variation="primary"
    >
      {buttonTitle}
    </ButtonLink>
  </div>
)

export default CardCta
